<template>
  <div class="cont">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand @click="scrollTop"
        ><span class="brandstyle">VILLANYSZERELŐ</span></b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav> </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#rolunk" class="menucenter"
            ><span class="menuitems"
              ><b-icon icon="person"></b-icon
              ><span class="menupoints">Rólunk</span></span
            ></b-nav-item
          >

          <b-nav-item href="#referencia" class="menucenter"
            ><span class="menuitems"
              ><b-icon icon="screwdriver"></b-icon
              ><span class="menupoints">Referencia</span></span
            ></b-nav-item
          >

          <b-nav-item href="#arak" class="menucenter"
            ><span class="menuitems"
              ><b-icon icon="cash"></b-icon
              ><span class="menupoints">Árak</span></span
            ></b-nav-item
          >

          <b-nav-item href="#kapcsolat" class="menucenter"
            ><span class="menuitems"
              ><b-icon icon="telephone"></b-icon
              ><span class="menupoints">Kapcsolat</span></span
            ></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "menu",
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.cont {
  position: sticky;
  top: 0;
  z-index: 999;
}
.brandstyle {
  color: #00a3d3;
  font-size: 120%;
  cursor: pointer;
}
.menuitems {
  color: white;
}
.menuitems:hover {
  color: #00a3d3;
}
.menupoints {
  font-size: 130%;
  margin-left: 10px;
}

@media screen and (max-width: 993px) {
  .menupoints {
    font-size: 130%;
    margin-left: 5px;
  }
  .menucenter {
    text-align: center;
  }
}
</style>
