<template>
  <div id="app">
    <topmenu />
    <footer1 />
    <router-view />
  </div>
</template>

<script>
import topmenu from "@/components/topmenu.vue";
import footer1 from "@/components/footer1.vue";

export default {
  name: "app",
  components: {
    topmenu,
    footer1
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  background-image: url("http://www.villanyszerelo.bendek.hu/img/bulb.png");
  background-repeat: repeat;
  background-color: #cccccc;
}
@media screen and (max-width: 993px) {

}
</style>
